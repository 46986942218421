import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import makeStyles from '@mui/styles/makeStyles';

// @mui/icons-material

// core components
import Header from "components/Header/Header.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ChangeText from "./SectionHeadersTribalismChangeText.js";
import Box from '@mui/material/Box';


import headersStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsSections/headersStyle.js";

const useStyles = makeStyles(headersStyle);

export default function SectionHeadersTribalism({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    // we've set the className to cd-section so we can make smooth scroll to it
<div className="cd-section" {...rest}>
      
      {/* HEADER 2 START */}
      <div>


        <div className={classes.pageHeader}>
                
            
        <video 
                    autoPlay
                    muted
                    loop
                    playsInline
                    className={classes.videoInsert}
                >
                    <source src='./img/video/footvid1-sm.mp4' type='video/mp4' />
                    Your device does not support playing 'video/mp4' videos
                </video>
          
          
          <div className={classes.containerHeader2}>
          <Box display={{ xs: 'none', md: 'block' }}>
            <GridContainer>
            
              
              <GridItem
                xs={12}
                sm={4}
                md={4}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textRight
                )}
              >


              <ChangeText className={classes.title} />

                


              </GridItem>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textLeft
                )}
              >


<h1 className={classes.title}> Welcome to Tribalism!</h1>




              </GridItem>
              </GridContainer>
              </Box>

              <Box display={{ xs: 'block', md: 'none' }}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={4}
                    md={4}
                    className={classNames(
                      classes.mlAuto,
                      classes.mrAuto,
                      classes.textCenter
                    )}
                  >


                  <ChangeText className={classes.title} />

                    



                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    md={8}
                    className={classNames(
                      classes.mlAuto,
                      classes.mrAuto,
                      classes.textCenter
                    )}
                  >




                    <h1 className={classes.title}> Welcome to Tribalism!</h1>
                



                  </GridItem>
                </GridContainer>
              </Box>

              

              <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classNames(classes.mlAuto, classes.mrAuto,classes.textCenter)}
              >
                <h3 style={{color:"white"}} className={classes.title}> Data & Analytics + SEM + Web Design</h3>
                <h1><br /></h1>

                <div className="ctaButton">

                          <Button color="xxx" simple size="lg" href="/contact-us">

                          <strong>Contact Us</strong>
                          </Button>

                          <p><br /></p>
                 </div>

              </GridItem>
            </GridContainer>
          </div>
          
        </div>

      </div>

      {/* HEADER 2 END */}
</div>

  );
}




