import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import makeStyles from '@mui/styles/makeStyles';

import styles from "styles/jss/nextjs-material-kit-pro/components/infoStyle.js";

const useStyles = makeStyles(styles);

export default function InfoArea({
  title,
  description,
  vertical,
  className,
  iconColor = "gray"
}) {
  const classes = useStyles();

  const infoAreaClasses = classNames({
    [classes.infoArea]: true,
    [className]: className !== undefined,
  });

  return (
    <div className={infoAreaClasses}>
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title}</h4>
        <div className={classes.description}>{description}</div>
      </div>
    </div>
  );
}

InfoArea.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  vertical: PropTypes.bool,
  className: PropTypes.string,
  iconColor: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ])
};
