import React from "react";
import makeStyles from "@mui/styles/makeStyles";
// @mui/icons-material
import Chat from "@mui/icons-material/Chat";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import Fingerprint from "@mui/icons-material/Fingerprint";
import GroupWork from "@mui/icons-material/GroupWork";
import Airplay from "@mui/icons-material/Airplay";
import LocationOn from "@mui/icons-material/LocationOn";
import Extension from "@mui/icons-material/Extension";
import ChildFriendly from "@mui/icons-material/ChildFriendly";
import WatchLater from "@mui/icons-material/WatchLater";
import Code from "@mui/icons-material/Code";
import FormatPaint from "@mui/icons-material/FormatPaint";
import Dashboard from "@mui/icons-material/Dashboard";
import ViewCarousel from "@mui/icons-material/ViewCarousel";
import AccessTime from "@mui/icons-material/AccessTime";
import AttachMoney from "@mui/icons-material/AttachMoney";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoAreaTribalism from "components/InfoArea/InfoAreaTribalism.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";
import Favorite from "@mui/icons-material/Favorite";
import Quote from "components/Typography/Quote.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import featuresStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div
        className="clients"
        //className={classes.team + " " + classes.section}
        //style={{ backgroundImage: "url('/img/bgs/bg1.jpg')"}}
        //style={{ backgroundColor: "lightgrey"}}
        style={{
          backgroundImage: "url('/img/services/images/GA4-background.jpg')",
          backgroundSize: "cover",
        }}
      >
        {/* Feature 4 START */}
        <div className={classes.container}>
          <div className={classes.features11}>
            <GridContainer>
              <div
                style={{
                  maxWidth: "500px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GridItem xs={12} sm={12} md={12}>
                  <h2 className={classes.title}>⚡️ News Flash ⚡️</h2>
                  <h4 className={classes.title}> </h4>

                  <Card
                    plain
                    raised
                    //backgroundlight
                    //hundred
                  >
                    <img
                      style={{
                        borderTopLeftRadius: "calc(.25rem - 1px)",
                        borderTopRightRadius: "calc(.25rem - 1px)",

                        width: "100%",
                        display: "block",
                      }}
                      //className={classes.imgCardTop}
                      here
                      src="/img/training-photos/TribalismTrainingOct23.png"
                      alt="GA4 Training"
                    />
                  </Card>
                </GridItem>
              </div>
            </GridContainer>
          </div>
        </div>

        {/* Feature 4 END */}
      </div>
    </div>
  );
}
