/* eslint-disable */
import React from "react";
import Image from "next/legacy/image"
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
// import Icon from "@mui/material/Icon";
// @mui/icons-material
// import Build from "@mui/icons-material/Build";
// import Subject from "@mui/icons-material/Subject";
// import FormatPaint from "@mui/icons-material/FormatPaint";
// import Code from "@mui/icons-material/Code";
// import Dashboard from "@mui/icons-material/Dashboard";
// import Timeline from "@mui/icons-material/Timeline";
// import Group from "@mui/icons-material/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

// import CardHeader from "components/Card/CardHeader.js";
// import Button from "components/CustomButtons/Button.js";
// import Muted from "components/Typography/Muted.js";
// import InfoArea from "components/InfoArea/InfoArea.js";
// import Badge from "components/Badge/Badge.js";

// import CardActionArea from '@mui/material/CardActionArea';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import FormatAlignLeft from "@mui/icons-material/FormatAlignLeft";

import projectsStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsSections/projectsStyle.js";

const useStyles = makeStyles(projectsStyle);

export default function SectionProjects({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div
        className="clients"
        //className={classes.team + " " + classes.section}
        //style={{ backgroundImage: "url('/img/home-page/back9.jpg')" }}
        style={{ backgroundImage: "url('/img/bgs/bg1.jpg')"}}
        //style={{ backgroundColor: "white"}}
      >
        {/* Project 1 START */}
        <Box display={{ xs: 'none', sm: 'none',md: 'none', lg:'none' , xl:'block'}}>
          <div className={classes.containerWide}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={
                  classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                }
              >
                <h2 className={classes.title}>
                  Welcome to our site!
                </h2>
                
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent='center'>
              <GridItem xs={12} xl={6}>
                <Card
                  plain
                  raised
                  style={{overflow: 'hidden'}}
                  //backgroundlight
                  //hundred
                >
                  <img
                    object-fit ="cover"
                    //className={classes.imgCardTop}
                    src="/img/office-photos/mk-8-sm-slim.jpg"
                    alt="search-engine-marketing"
                  />
                  <CardBody 
                    background                 
                    style={{
                    backgroundColor: "white", margin:"0px",borderBottomLeftRadius: "calc(.25rem - 1px)",borderBottomRightRadius: "calc(.25rem - 1px)"
                  }}>

                    <Box 
                      sx={{
                        color: "#3C4858",
                        fontSize:"17px"
                      }}
                    >
                      Thanks for stopping by! We are a technology company dedicated to solving client probems. 
                      <br /><br />
                      Analytics is in our DNA. But we are web pioneers as well.
                      <br /><br />
                      Our promise is to make the amazing possible (by bringing your awesome ideas to life)!
                      <br /><br />
                    </Box>
                    <div style={{color: "#3C4858", fontSize:"17px", fontFamily:"Helvetica"}}>
                      <i>
                        <strong>Thanks! The Tribalism Team</strong>
                      </i>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={3} md={3} alignItems="center" justify="center">
                <div style={{ display:'flex', justifyContent:'center' }}>
                  <Card 
                    raised  
                    style={{overflow: 'hidden', borderRadius: '10px'}}
                    //background
                    //style={{
                    //  backgroundImage: "url('/img/sections/services/google-analytics-training/1.jpg')",
                    //}}
                  >
                    <div 
                       style={{paddingLeft: "10px",backgroundImage: "url('/img/melbourne-streetart/17_vsmall.jpg')"}}
                    >
                      <Box 
                        sx={{
                          color: "#FFF",
                          fontSize:"17px",                                        
                        }}
                      >
                        <br />
                        <strong>...and heres whats spinning at Tribalism...</strong>
                        <br /><br />
                      </Box>
                    </div>
                    <CardActionArea sx={{mt: -1}}>
                      <CardMedia
                        sx={{border: 0}}
                        component="iframe"
                        alt="spotify"
                        //className={classes.media}
                        height="470px"
                        width="100%"
                        image="https://open.spotify.com/embed/playlist/4ReXZkfF4EHv1OEqudIZvX?utm_source=generator"
                        //title="Contemplative Reptile"
                      />
                    </CardActionArea>
                  </Card> 
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Box>
        <Box display={{ xs: 'block', sm: 'block',md: 'block', lg:'block' , xl:'none' , }}>
          <div className={classes.containerWide}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={
                  classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                }
              >
                <h2 className={classes.title}>
                  Welcome to our site!
                </h2>
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent='center'>
              <GridItem xs={12} sm={6} md={6}>
                <Card
                  plain
                  raised
                  style={{overflow: 'hidden'}}
                  //backgroundlight
                  //hundred
                >
                  <img
                    //className={classes.imgCardTop}
                    src="/img/office-photos/mk-8-sm-slim.jpg"
                    alt="search-engine-marketing"
                    object-fit ="cover"
                  />
                  <CardBody 
                    background
                    style={{
                      backgroundColor: "white", 
                      margin:"0px", 
                      borderBottomLeftRadius: "calc(.25rem - 1px)", 
                      borderBottomRightRadius: "calc(.25rem - 1px)"
                  }}>

                    <Box sx={{
                      color: "#3C4858",
                      fontSize:"17px"
                    }}>
                  
                      Thanks for stopping by! We are a technology company dedicated to solving client probems. 
                      <br /><br />
                      Analytics is in our DNA. But we are web pioneers as well.
                      <br /><br />
                      Our promise is to make the amazing possible (by bringing your awesome ideas to life)!
                      <br /><br />
                    </Box>
                    <div style={{color: "#3C4858", fontSize:"17px", fontFamily:"Helvetica"}}>
                      <i><strong>Thanks! The Tribalism Team</strong></i>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={3} md={3} alignItems="center" justify="center">
                <div style={{ display:'flex', justifyContent:'center'}}>
                  <Card
                    raised
                    style={{overflow: 'hidden', borderRadius: '10px'}}
                    //background
                    //style={{
                    //  backgroundImage: "url('/img/sections/services/google-analytics-training/1.jpg')",
                    //}}
                  >
                    <div 
                       style={{
                        paddingLeft: "10px", 
                        backgroundImage: "url('/img/melbourne-streetart/17_vsmall.jpg')"
                      }}
                    >
                      <Box 
                        style={{
                          color: "#FFF",
                          fontSize:"17px",  
                        }}
                      >
                        <br />
                        <strong>...and heres whats spinning at Tribalism...</strong>
                        <br /><br />
                      </Box>
                    </div>
                    <CardActionArea sx={{mt: -1}}>
                      <CardMedia
                        sx={{border: 0}}
                        component="iframe"
                        alt="spotify"
                        //className={classes.media}
                        height="470px"
                        width="100%"
                        image="https://open.spotify.com/embed/playlist/4ReXZkfF4EHv1OEqudIZvX?utm_source=generator"
                        //title="Contemplative Reptile"
                      />
                    </CardActionArea>
                  </Card> 
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Box>
        {/* Project 1 END */}
      </div>
    </div>
  );
}
