import React from "react";
import makeStyles from '@mui/styles/makeStyles';
// @mui/icons-material
// import  from "@mui/icons-material/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";

import teamsStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsSections/teamsStyle.js";

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>

      {/* B2C Clients Start */}
      <div
      className="clients"
        //className={classes.team + " " + classes.section}
        //style={{ backgroundImage: "url('/img/services/images/office.jpeg')",  backgroundSize:"100% 100%"}}
        //style={{ backgroundImage: "url('/img/home-page/back9.webp')" }}
        //style={{ backgroundColor: "lightgrey"}}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Some Of Our Services</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
          
            <GridItem xs={6} sm={4} md={4}>
              <Card profile plain className={classes.card5} style={{paddingLeft: "0%",paddingRight: "0%",paddingTop: "0%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <a href="/services/social-media-marketing-services">
                        <img
                          src="/img/sections/homepage/services/social-media-marketing-sm.webp"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4}>
            <Card profile plain className={classes.card5} style={{paddingLeft: "0%",paddingRight: "0%",paddingTop: "0%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <a href="/services/google-ads-services">
                        <img
                          src="/img/sections/homepage/services/search-engine-marketing-sm.webp"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4}>
            <Card profile plain className={classes.card5} style={{paddingLeft: "0%",paddingRight: "0%",paddingTop: "0%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <a href="/services/seo-services">
                        <img
                          src="/img/sections/homepage/services/SEO-sm.webp"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={3} md={3}>
            <Card profile plain className={classes.card5} style={{paddingLeft: "0%",paddingRight: "0%",paddingTop: "0%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <a href="/services/analytics-services">
                        <img
                          src="/img/sections/homepage/services/analytics-sm.webp"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
           
            <GridItem xs={6} sm={3} md={3}>
              <Card profile plain className={classes.card5} style={{paddingLeft: "0%",paddingRight: "0%",paddingTop: "0%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <a href="/services/google-tag-manager-services">
                        <img
                          src="/img/sections/homepage/services/google-tag-manager-sm.webp"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={3} md={3}>
              <Card profile plain className={classes.card5} style={{paddingLeft: "0%",paddingRight: "0%",paddingTop: "0%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <a href="/services/training">
                        <img
                          src="/img/sections/homepage/services/training-sm.webp"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={3} md={3}>
              <Card profile plain className={classes.card5} style={{paddingLeft: "0%",paddingRight: "0%",paddingTop: "0%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <a href="/services/web-development-services">
                        <img
                          src="/img/sections/homepage/services/web-development-sm.webp"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
            

          </GridContainer>
          
        </div>

        <br />
        {/* B2C Clients END */}
      </div>

      

      

      
    </div>
  );
}
