import React from 'react';


/**
 * WelcomeText
 * @returns 
 */
const ChangeText = ({className}) => {
  const texts = [
    "こんにちは...", 
    "Bonjour...", 
    "你好...", 
    "Hello...", 
    "Hola...", 
    "Γειά σου...", 
    "Здраво..."
  ];
  const [counter, setCounter] = React.useState(0);

  // Run interval
  React.useEffect(() => {
    if(!window) return;

    const increaseCounter = () => {
      setCounter(prevCounter => {
        if(prevCounter + 1 >= texts.length) {
          return 0;
        } else {
          return prevCounter + 1;
        }
      });
    };

    const interval = setInterval(() => {
      increaseCounter();
    }, 1000);

    return(() => {
      if(!window) return;
      clearInterval(interval);
    })
  }, []);
  
  // Return Component
  return (
    <h1 className={className}>{texts[counter]}</h1>
  )
}

export default ChangeText;
