import React from "react";
import makeStyles from "@mui/styles/makeStyles";
// @mui/icons-material
import Chat from "@mui/icons-material/Chat";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import Fingerprint from "@mui/icons-material/Fingerprint";
import GroupWork from "@mui/icons-material/GroupWork";
import Airplay from "@mui/icons-material/Airplay";
import LocationOn from "@mui/icons-material/LocationOn";
import Extension from "@mui/icons-material/Extension";
import ChildFriendly from "@mui/icons-material/ChildFriendly";
import WatchLater from "@mui/icons-material/WatchLater";
import Code from "@mui/icons-material/Code";
import FormatPaint from "@mui/icons-material/FormatPaint";
import Dashboard from "@mui/icons-material/Dashboard";
import ViewCarousel from "@mui/icons-material/ViewCarousel";
import AccessTime from "@mui/icons-material/AccessTime";
import AttachMoney from "@mui/icons-material/AttachMoney";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoAreaTribalism from "components/InfoArea/InfoAreaTribalism.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";
import Favorite from "@mui/icons-material/Favorite";
import Quote from "components/Typography/Quote.js";
import Image from "next/legacy/image";

import featuresStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div
        className="clients"
        //className={classes.team + " " + classes.section}
        //style={{ backgroundImage: "url('/img/bgs/bg1.jpg')" }} //home_image-sm.webp
        //style={{
        //  backgroundImage:
        //    "url('/img/sections/services/all-services/melbourne-sm.webp')",
        //}}
        //style={{ backgroundColor: "black" }}
      >
        {/* Feature 4 START */}
        <div className={classes.container}>
          <h2 className={classes.title}>What we do</h2>

          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
              height: "100%",
            }}
          >
            <h4>
              We are a service company specialising in <strong>data & analytics</strong>, 
              <strong> search engine marketing</strong> and <strong>web design</strong>.
              
              We offer implementation and training services
              across all of these and related areas. 
              <br />
              <br />
              <strong>Data & Analytics:</strong> We have  the highest quality and most experienced analytics staff you can find. We take pride in our work and love what we do. All local, from extremely technical disciplines - we cover everything to do with data & analytics. We are experts in GTM, GA4, BigQuery,
              AWS, Streamlit, D3/Observable, Redash and other platforms like Snowplow. Want an epic dashboard, awesome analytics investigation, a completely redesigned reporting system or a process to integrate AI into your workflow? We do it all!
              <br />
              <br />
              <strong>Search Engine Marketing:</strong> We offer SEM (google ads and microsoft ads) for select clients. Select, because we believe SEM is very technical and complex for most reasonably sized accounts. Thats why we have a competitive advantage, we have the technical skills and experience to get these tools and strategies - properly firing. We do not work on low cost/low effort/low investment search campaigns. Our focus is on high quality, well resourced and technically superior search engine marketing management.
              <br />
              <br />
              <strong>Web Design:</strong> We offer web design and implementation for select clients. Our website specialties
              include Next.js/React, Wordpress, Webflow, Shopify and Squarespace
              - but we've worked with numerous platforms from Magento to Drupal
              and .NET.
              <br />

            </h4>
          </div>

          
          <br />
          <br />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Button variant="outlined" color="rose" size="lg" href="/services">
            See Our Services
          </Button>
        </div>

        {/* Feature 4 END */}
      </div>
    </div>
  );
}
