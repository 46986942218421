import React from "react";

import makeStyles from "@mui/styles/makeStyles";
// @mui/icons-material
import Check from "@mui/icons-material/Check";
import Warning from "@mui/icons-material/Warning";
// core components
import SnackbarContent from "/components/Snackbar/SnackbarContentTribalism.js";
import Clearfix from "/components/Clearfix/Clearfix.js";
import notificationsStyles from "/styles/jss/nextjs-material-kit-pro/pages/componentsSections/notificationsStyles.js";

import Link from "next/link";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(notificationsStyles);

export default function SectionNotifications() {
  const classes = useStyles();
  return (
    <div>
      <SnackbarContent
        message={
          <span>
            <b>📣&nbsp;&nbsp;&nbsp;&nbsp;Announcement: </b>Next GA4 Training
            Session 2023-11-15. For more info click here:
            <Link href="/services/training/google-analytics-training">
              <Button color="rose" size="sm" style={{ marginLeft: "20px" }}>
                More Info
              </Button>
            </Link>{" "}
          </span>
        }
        close
        color="info"
        //icon="info_outline"
      />

      <Clearfix />
    </div>
  );
}
